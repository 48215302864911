import React, { useState } from "react";
import ModalBase from "./ModalBase";
import { API } from "../../js/_variables";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

export default function SendInfo({ onClose, userId }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState('')
  const [street, setStreet] = useState("");
  const [ne, setNE] = useState("");
  const [cp, setCP] = useState("");

  const [needFact, setNeedFact] = useState(false);
  const [factName, setFactName] = useState("");
  const [factEmail, setFactEmail] = useState("");
  const [factRFC, setFactRFC] = useState("");
  const [factRS, setFactRS] = useState("");
  const [factCalle, setFactCalle] = useState("");
  const [factNE, setFactNE] = useState("");
  const [factNI, setFactNI] = useState("");
  const [factCP, setFactCP] = useState("");
  const [factMunicipio, setFactMunicipio] = useState("");
  const [factEstado, setFactEstado] = useState("");
  const [factPais, setFactPais] = useState("");
  const [factColonia, setFactColonia] = useState("");

  function getNameById(id) {
    const params = new FormData();
    params.append("id", id);

    axios
      .post(`${API}/getNameById`, params)
      .then((res) => {
        setName(res.data.data);
      })
      .catch(() => {
        setName(`Error obteniendo el nombre, para el Id: ${id}`)
      });
  }

  useEffect(() => {
    getNameById(userId)
  }, [])

  function enviarDatosDeCompra(usrId) {
    const url = `${API}compras_newOrder`;
    // const url = "https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/compras_newOrder"

    let method = "CARD";
    let userCarrito = JSON.parse(localStorage.getItem("carrito")).articulos;
    let userNombre = name;
    let userCalle = street;
    let userNE = ne;
    let userCP = cp;
    // let factName = "";
    // let factEmail = "";
    // let factRFC = "";
    // let factRS = "";
    // let factCalle = "";
    // let factNE = "";
    // let factNI = "";
    // let factPais = "";
    // let factEstado = "";
    // let factMunicipio = "";
    // let factColonia = "";
    // let factCP = "";
    let userCarritoNoParse = localStorage.getItem("carrito");

    if (userCalle && userNE && userCP) {
      let data2 = new FormData();
      data2.append("key", "testKey");
      data2.append("usuario", `${usrId}`);
      data2.append("email", `${email}`);
      data2.append("articulos", `${JSON.stringify(userCarrito)}`);
      data2.append(
        "factura",
        `{"nombre" : "${factName}","email" : "${factEmail}","rfc" : "${factRFC}","rs" : "${factRS}","calle" : "${factCalle}","ne" : "${factNE}","ni" : "${factNI}","colonia" : "${factColonia}","cp" : "${factCP}","municipio" : "${factMunicipio}","estado" : "${factEstado}","pais" : "${factPais}"}`
      );
      data2.append(
        "sending",
        `{"nombre" : "${userNombre}","email" : "${email}","rfc" : "","rs" : "","calle" : "${userCalle}","ne" : "${userNE}","ni" : "","colonia" : "","cp" : "${userCP}","municipio" : "","estado" : "","pais" : ""}`
      );
      data2.append("comm", "Sin comentarios");
      data2.append("method", "CARD");
      data2.append("postal", `${userCP}`);

      // console.log(data2)

      let xhr2 = new XMLHttpRequest();
      xhr2.open("POST", url, true);
      xhr2.onload = (response) => {
        console.log(response)
        const responseJSON = JSON.parse(response.target.responseText);
        console.log(responseJSON);

        // navigate('/pago-exitoso')
        window.location = responseJSON.url;
      };
      xhr2.send(data2);
    } else {
      alert(
        "Es necesario llenar todos los campos para poder realizar tu envío"
      );
    }
  }

  return (
    <ModalBase closeModal={onClose}>
      <h2 className="text-secondary font-semibold text-xl mb-8">
        Información de envío
      </h2>

      <div>
        <input
          type="email"
          className="form-input mb-4"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo para el recibo"
        />
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <input
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            type="text"
            className="form-input col-span-2"
            placeholder="Calle"
          />
          <input
            value={ne}
            onChange={(e) => setNE(e.target.value)}
            type="text"
            className="form-input mb-2"
            placeholder="Número"
          />
          <input
            value={cp}
            onChange={(e) => setCP(e.target.value)}
            type="number"
            className="form-input mb-2"
            placeholder="Código Postal"
          />
        </div>
      </div>

      <div className="flex items-center gap-2 py-8">
        <input
          type="checkbox"
          name="needFactura"
          id="needFactura"
          value={needFact}
          onChange={(e) => setNeedFact(e.target.checked)}
        />
        <label htmlFor="needFactura">Necesito factura</label>
      </div>

      {needFact && (
        <div>
          <input
            type="text"
            value={factName}
            onChange={(e) => setFactName(e.target.value)}
            placeholder="Nombre para la factura"
            className="form-input mb-4"
          />
          <input
            type="email"
            value={factEmail}
            onChange={(e) => setFactEmail(e.target.value)}
            placeholder="Correo para la factura"
            className="form-input mb-4"
          />
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mb-4'>
            <input
              type="text"
              value={factRFC}
              onChange={(e) => setFactRFC(e.target.value)}
              placeholder="RFC"
              className="form-input col-span-2"
            />
            <input
              type="text"
              value={factRS}
              onChange={(e) => setFactRS(e.target.value)}
              placeholder="Razón Social"
              className="form-input col-span-2"
            />
            <input
              type="text"
              value={factCalle}
              onChange={(e) => setFactCalle(e.target.value)}
              placeholder="Calle"
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="number"
              value={factNE}
              onChange={(e) => setFactNE(e.target.value)}
              placeholder="N.E."
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="number"
              value={factNI}
              onChange={(e) => setFactNI(e.target.value)}
              placeholder="N.I."
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="text"
              value={factCP}
              onChange={(e) => setFactCP(e.target.value)}
              placeholder="C.P."
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="text"
              value={factColonia}
              onChange={(e) => setFactColonia(e.target.value)}
              placeholder="Colonia"
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="text"
              value={factMunicipio}
              onChange={(e) => setFactMunicipio(e.target.value)}
              placeholder="Municipio"
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="text"
              value={factEstado}
              onChange={(e) => setFactEstado(e.target.value)}
              placeholder="Estado"
              className="form-input col-span-2 md:col-span-1"
            />
            <input
              type="text"
              value={factPais}
              onChange={(e) => setFactPais(e.target.value)}
              placeholder="País"
              className="form-input col-span-2 md:col-span-1"
            />
          </div>
        </div>
      )}

      <div className="text-right mt-4">
        <button
          className="btn-primary"
          onClick={() => enviarDatosDeCompra(userId)}
        >
          Proceder al pago
        </button>
      </div>
    </ModalBase>
  );
}
