import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { API } from "../js/_variables";

const CatsSlider = () => {
  const [categorias, setCategorias] = useState([])

  useEffect(() => {
    // Get Featured Products
    const queryData = new FormData()
    queryData.append('key', 'testKey')

    axios.post(
      `${API}getCategorias`, queryData
    )
    .then(res => {
      if (res.status === 200) {
        setCategorias(res.data.inventory)
        console.log(res.data.inventory)
      }
    })
    .catch(err => console.log(err))
  }, [])

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className="py-8"
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1280,
          },
          items: 4,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 480,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1280,
            min: 480,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {
        categorias.length > 0 && categorias.map(categoria => (
          <div className="rounded shadow bg-white text-center py-8 px-4 m-4 transition-all duration-150 hover:scale-105" key={categoria.categoria_id}>
            <Link to={`/catalogo?categoria=${categoria.categoria_nombre}`} className="capitalize">
              <div className="relative rounded-full aspect-square mb-4 overflow-hidden">
                <img src={`https://static.grupogepesa.com/assets/img/categorias/${categoria.categoria_id}.png`} className="object-cover w-full h-full" />
              </div>
              <span className="font-medium text-xl">
                {categoria.categoria_nombre}
              </span>
            </Link>
          </div>
        ))
      }
    </Carousel>
  );
};

export default CatsSlider;
