import axios from "axios";
import { useEffect, useState, lazy, Suspense } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ProductSlider from "../components/ProductSlider";
import { API } from "./../js/_variables";
import { addToCart, agregarCarrito } from "./../js/cart.js";
import Helmet from "react-helmet";

const ImageModal = lazy(() => import("./../components/Modals/ImageModal"));
const ProductToCart = lazy(() =>
  import("./../components/Modals/ProductToCart")
);

function ProductPage() {
  let { productId } = useParams();

  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalProductToCart, setShowModalProductToCart] = useState(false);
  const [articulo, setArticulo] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [activeImg, setActiveImg] = useState(
    "https://static.grupogepesa.com/assets/img/productos/4.png"
  );

  const getProductDetails = () => {
    const queryData = new FormData();
    queryData.append("key", "testKey");
    queryData.append("id", productId);

    axios
      .post(`${API}getProdById`, queryData)
      .then((res) => {
        setArticulo(res.data.articulos[0]);
        console.log(res.data.articulos[0]);
        setActiveImg(
          `https://static.grupogepesa.com/assets/img/productos/${res.data.articulos[0].imagen_1}`
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (productId) getProductDetails();
  }, []);

  return (
    <>
      <Helmet>{articulo.nombre && <title>{articulo.nombre}</title>}</Helmet>
      <section className="section">
        <div className="rounded border shadow-lg p-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="">
              <div
                className="w-full aspect-square rounded-lg overflow-hidden relative hover:cursor-pointer"
                onClick={() => setShowModalImage(true)}
              >
                {activeImg && (
                  <img
                    src={activeImg}
                    alt=""
                    className="object-cover w-full h-full"
                  />
                )}
              </div>
              <div className="grid grid-cols-3 gap-4 py-4">
                {articulo.imagen_1 && (
                  <div
                    className="aspect-square rounded-lg overflow-hidden relative hover:cursor-pointer"
                    onClick={() =>
                      setActiveImg(
                        `https://static.grupogepesa.com/assets/img/productos/${articulo.imagen_1}`
                      )
                    }
                  >
                    <img
                      src={`https://static.grupogepesa.com/assets/img/productos/${articulo.imagen_1}`}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                )}
                {articulo.imagen_2 && (
                  <div
                    className="aspect-square rounded-lg overflow-hidden relative hover:cursor-pointer"
                    onClick={() =>
                      setActiveImg(
                        `https://static.grupogepesa.com/assets/img/productos/${articulo.imagen_2}`
                      )
                    }
                  >
                    <img
                      src={`https://static.grupogepesa.com/assets/img/productos/${articulo.imagen_2}`}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                )}
                {articulo.imagen_3 && (
                  <div
                    className="aspect-square rounded-lg overflow-hidden relative hover:cursor-pointer"
                    onClick={() =>
                      setActiveImg(
                        `https://static.grupogepesa.com/assets/img/productos/${articulo.imagen_3}`
                      )
                    }
                  >
                    <img
                      src={`https://static.grupogepesa.com/assets/img/productos/${articulo.imagen_3}`}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-2 p-4">
              <h1 className="text-dark font-bold text-2xl mb-8">
                {articulo.nombre}
              </h1>
              <p className="text-green-600 font-bold mb-4">
                $ {articulo.precio} MXN
              </p>

              <p className="text-dark mb-2">{articulo.descripcion}</p>

              {articulo.content && (
                  <div
                    dangerouslySetInnerHTML={{ __html: articulo.content }}
                  ></div>
                )}

              <div className="mt-8">
                <input
                  type="number"
                  min={1}
                  max={articulo.cantidad}
                  className="border p-2"
                  value={cantidad}
                  onChange={(e) => {
                    setCantidad(e.target.value);
                  }}
                />

                <button
                  className="btn-primary"
                  onClick={() => {
                    agregarCarrito(
                      articulo.id,
                      cantidad,
                      articulo.precio,
                      articulo.precio_envio,
                      articulo.nombre,
                      articulo.imagen_1
                    );
                    setShowModalProductToCart(true);
                  }}
                >
                  Agregar al carrito
                </button>
              </div>
            </div>
          </div>
        </div>

        <ProductSlider />
      </section>
      {showModalImage && (
        <Suspense fallback={<div>Cargando...</div>}>
          <ImageModal
            imageSrc={activeImg}
            onClose={() => setShowModalImage(false)}
          />
        </Suspense>
      )}
      {showModalProductToCart && (
        <Suspense fallback={<div>Cargando...</div>}>
          <ProductToCart onClose={() => setShowModalProductToCart(false)} />
        </Suspense>
      )}
    </>
  );
}

export default ProductPage;
