import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { API } from "../js/_variables";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Contacto() {
  const [nombre, setnombre] = useState('')
  const [telefono, settelefono] = useState('')
  const [correo, setcorreo] = useState('')
  const [asunto, setasunto] = useState('')
  const [mensaje, setmensaje] = useState('')

  const enviarContacto = () => {
    if (!correo) {
      alert('Es necesario escribir tu correo electrónico para enviar el correo')
      return 0
    }
    if (!telefono) {
      alert('Es necesario escribir tu comentario')
      return 0
    }
    if (!nombre) {
      alert('Es necesario escribir tu nombre')
      return 0
    }

    const queryData = new FormData()
    queryData.append('key', 'testKey')
    queryData.append('nombre', nombre)
    queryData.append('telefono', telefono)
    queryData.append('correo', correo)
    queryData.append('asunto', asunto)
    queryData.append('comentarios', mensaje)

    axios.post(`${API}sendMail`, queryData)
    .then(res => {
      if (res.status === 200) {
        alert('Tu correo se ha enviado')
      }
    })
    .catch(err => console.log(err))
  }
  return (
    <>
      <Helmet>
        <title>Contáctanos | Grupo Gepesa</title>
      </Helmet>

      <div className="py-8">
        <motion.h1
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ type: "spring", duration: 1, delay: 0 }}
          className="text-primary text-center font-bold text-5xl mb-8"
        >
          Contacto
        </motion.h1>

        <div className="mt-8 md:mt-16">
          <section className="section">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <motion.div
                initial={{ opacity: 0, y: -200 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                exit={{ opacity: 0, y: -200 }}
                transition={{ type: "spring", duration: 2, delay: 0 }}
              >
                <img src="/img/contacto.png" alt="" />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                exit={{ opacity: 0, y: 200 }}
                transition={{ type: "spring", duration: 2, delay: 0 }}
                className="flex items-center p-8"
              >
                <div>
                  <span className="text-darklight">
                    ¿DESEAS CONSTRUIR O REMODELAR TU HOGAR O NEGOCIO?
                  </span>
                  <h2 className="text-dark font-medium text-3xl mb-4">
                    Tenemos todo lo que <b>necesitas.</b>
                  </h2>
                  <p className="text-dark mb-8">
                    En GRUPO GEPESA encontraras una gran variedad de artículos
                    garantizados para terminados en el hogar; la mejor calidad,
                    las mejores marcas y al mejor precio.
                  </p>
                  <Link to="/nosotros" className="btn-primary rounded-full">
                    ¿Quiénes somos?
                  </Link>
                </div>
              </motion.div>

              {/* Datos de contacto */}
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                exit={{ opacity: 0, x: -200 }}
                transition={{ type: "spring", duration: 2, delay: 0 }}
              >
                <div className="bg-darkblue rounded-lg shadow p-4">
                  <h2 className="text-white font-medium text-3xl mb-4">
                    GRUPO <b>GEPESA</b>
                  </h2>
                  <p className="text-white mb-4">
                    Contamos con atención personalizada y servicio a domicilio,
                    llámanos, escríbenos o visítanos.
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    <a
                      href="https://g.page/pisosgepesa?share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">
                          Av. Base Aérea #1460
                        </span>
                        <span className="text-dark">Zapopan, Jalisco.</span>
                        <span className="text-primary font-medium">
                          Ubicación
                        </span>
                      </div>
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">Horarios</span>
                        <span className="text-dark">Lu - Vi: 9am. a 6pm.</span>
                        <span className="text-dark">Sá: 9am. a 3pm.</span>
                      </div>
                    </a>
                    <a
                      href="https://goo.gl/maps/itKi1Egy6UGrXssa9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">
                          Blvd. Bosques de San Isidro Norte #1847-20
                        </span>
                        <span className="text-dark">Zapopan, Jalisco.</span>
                        <span className="text-primary font-medium">
                          Ubicación
                        </span>
                      </div>
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">Horarios</span>
                        <span className="text-dark">Lu - Vi: 9am. a 6pm.</span>
                        <span className="text-dark">Sá: 9am. a 3pm.</span>
                      </div>
                    </a>
                    <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                      <span className="text-dark font-bold">Teléfonos</span>
                      <span className="text-dark">
                        <a href="tel:13711206">1371 - 1206</a>
                      </span>
                      <span className="text-dark">
                        <a href="tel:36855648">3685 - 5648</a>
                      </span>
                    </div>
                    <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                      <span className="text-dark font-bold">
                        Cel / WhatsApp
                      </span>
                      <span className="text-dark">
                        <a href="tel:3326558007">33 - 2655 - 8007</a>
                      </span>
                    </div>
                    <a
                      href="https://www.facebook.com/pisosgepesa/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">Facebook</span>
                        <span className="text-primary">GRUPO GEPESA</span>
                      </div>
                    </a>
                    <a
                      href="https://www.instagram.com/grupogepesa/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">Instagram</span>
                        <span className="text-primary">@GRUPOGEPESA</span>
                      </div>
                    </a>
                    <a
                      href="mailto:ventas@grupogepesa.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="col-span-2"
                    >
                      <div className="flex flex-col bg-white rounded-xl p-2 h-full">
                        <span className="text-dark font-bold">Email</span>
                        <span className="text-primary">
                          ventas@grupogepesa.com
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </motion.div>

              {/* Formulario */}
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                exit={{ opacity: 0, x: 200 }}
                transition={{ type: "spring", duration: 2, delay: 0 }}
              >
                <div className="bg-neutral-100 rounded-xl border shadow-lg overflow-hidden p-4 h-full flex flex-col justify-center">
                  <h2 className="text-black text-center font-bold text-2xl mb-4">
                    ¿Necesitas asesoría para tu proyecto? <br />
                    ¡Contáctanos!
                  </h2>
                  <p className="text-dark text-center mb-4">
                    Podemos ayudarte con asesoría personalizada para tu
                    remodelación o construcción.
                  </p>

                  <input
                    type="text"
                    className="w-full bg-transparent border border-darklight p-2 mb-4 rounded"
                    placeholder="Nombre"
                    onChange={(e) => setnombre(e.target.value)}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="number"
                      className="w-full bg-transparent border border-darklight p-2 mb-4 rounded"
                      placeholder="Teléfono"
                      onChange={(e) => settelefono(e.target.value)}
                    />
                    <input
                      type="email"
                      className="w-full bg-transparent border border-darklight p-2 mb-4 rounded"
                      placeholder="Correo"
                      onChange={(e) => setcorreo(e.target.value)}
                    />
                  </div>
                  <input
                    type="text"
                    className="w-full bg-transparent border border-darklight p-2 mb-4 rounded"
                    placeholder="Asunto"
                    onChange={(e) => setasunto(e.target.value)}
                  />
                  <textarea
                    rows="4"
                    className="w-full bg-transparent border border-darklight p-2 mb-4 rounded"
                    placeholder="Mensaje"
                    onChange={(e) => setmensaje(e.target.value)}
                  ></textarea>

                  <div className="text-center">
                    <button className="btn-primary rounded-full" onClick={() => enviarContacto()}>Enviar</button>
                  </div>
                </div>
              </motion.div>

              {/* Mapas */}
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                exit={{ opacity: 0, y: -200 }}
                transition={{ type: "spring", duration: 2, delay: 0 }}
                className="rounded-xl overflow-hidden"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.0112181251047!2d-103.4431334!3d20.7503394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af56382560e1%3A0x975790373739ad09!2sGrupo%20Gepesa!5e0!3m2!1ses!2smx!4v1654888146130!5m2!1ses!2smx"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="border-0"
                ></iframe>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                exit={{ opacity: 0, y: -200 }}
                transition={{ type: "spring", duration: 2, delay: 0 }}
                className="rounded-xl overflow-hidden"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12549.024712069651!2d-103.39627801017743!3d20.756979607197998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428a5f70112cd73%3A0xbe0ecdd15f9936f4!2sGrupo%20Gepesa%20Suc.%20Centinela!5e0!3m2!1ses!2smx!4v1657312090496!5m2!1ses!2smx"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="border-0"
                ></iframe>
              </motion.div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
