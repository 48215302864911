import React from 'react'
import { motion } from 'framer-motion'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { API } from '../js/_variables'

export default function PagoExitoso() {
  let [searchParams, setSearchParams] = useSearchParams()
  let c = searchParams.get('c')

  useEffect(() => {
    // Update Purchase Status
    const params = new FormData()
    params.append('c', c)
    params.append('status', 'Complete')

    axios.post(
      `${API}/compra_updateStatus`, params
    )
    .then(res => console.log(res))
    .catch(err => console.log(err))
  }, [])

  return (
    <>
      <section className="section">
      <motion.h1
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ type: 'spring', duration: 1, delay: 0 }} 
          className='text-primary text-center font-bold text-5xl mb-8'
        >
          Pago realizado con éxito
        </motion.h1>
        <p className="text-dark text-center">
          En los próximos minutos te llegará un correo con la información de tu envío
        </p>
      </section>
    </>
  )
}
