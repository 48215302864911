function agregarCarrito(id, quantity, price, sendPrice, name, image) {
  let orden = { 
    id: parseInt(id), 
    image: image, 
    nombre: name, 
    precio: price, 
    articulos: parseInt(quantity), 
    precio_envio: sendPrice 
  }
  
  let carrito = {}

  // El carrito ya existe
  if (localStorage.getItem('carrito')) {
      if (existeEnCarrito(parseInt(id)))  // El producto ya está en el carrito
          actualizarEnCarrito(orden)
      else
          agregarNuevoEnCarrito(orden)    // El producto NO está en el carrito
  } else {    // El carrito no existe
      carrito = {
          articulos: [orden]
      }

      localStorage.setItem('carrito', JSON.stringify(carrito))      
  }
}

function existeEnCarrito(idToSearch) {
  // Lee el carrito existente
  const carrito = JSON.parse(localStorage.getItem('carrito'))
  const articulos = carrito.articulos

  // Bandera para controlar el return de la función
  let existe = false

  // Si encuentra al menos un elemento con el ID a buscar, marca la bandera como verdadera
  articulos.forEach(e => {
      if (e.id === idToSearch)
          existe = true
  })
  
  // Si al menos un elemento coincidió (no debería ser más de uno) entonces retorna true.
  return existe
}

function agregarNuevoEnCarrito(orden) {
  // Lee el carrito existente
  let carrito = JSON.parse(localStorage.getItem('carrito'))
  let articulos = carrito.articulos

  // Agrega el nuevo elemento (se debió verificar previamente que no existiera ya).
  articulos.push(orden)

  // Actualiza el objeto en localStorage con la nueva orden agregada.
  localStorage.setItem('carrito', JSON.stringify({ articulos: articulos }))
}

function actualizarEnCarrito(orden) {
  // Lee el carrito ya existente.
  const arr = JSON.parse(localStorage.getItem('carrito'))
  const carrito = arr.articulos

  // Bandera para saber el índice del elemento ya existente que se busca actualizar.
  let indexProduct
  
  // Crea un nuevoCarrito con los elementos que NO coinciden con el ID y guarda el Index del que sí coincide.
  let nuevoCarrito = carrito.filter((articulo, index) => {
      if (articulo.id === orden.id)
          indexProduct = index

      return articulo.id !== orden.id
  })

  // Suma la cantidad que ya existía previamente con la que se quiere agregar.
  let nuevaCantidad = orden.articulos + carrito[indexProduct].articulos
  
  // Ya sumadas las cantidades, agrega el producto al array que contenía a los elementos distintos.
  nuevoCarrito.push({ id: orden.id, image: orden.image, nombre: orden.nombre, precio: orden.precio, articulos: nuevaCantidad, precio_envio: orden.precio_envio })

  // Actualiza el localStorage con todos los productos y cantidades actualizadas.
  localStorage.setItem('carrito', JSON.stringify({ articulos: nuevoCarrito}))
}

export {
  agregarCarrito,
  existeEnCarrito,
  actualizarEnCarrito,
  agregarNuevoEnCarrito,
}