import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import CartProduct from '../components/CartProduct'
import { formatedNumber } from '../js/formatNumbers'
import { motion } from 'framer-motion'
import SendInfo from '../components/Modals/SendInfo'
import { getCookie } from '../js/_cookies'
import { useNavigate } from 'react-router-dom'
import { API } from '../js/_variables'

export default function Carrito() {
  const navigate = useNavigate()

  const [userId, setUserId] = useState('')
  const [showModalSendInfo, setShowModalInfo] = useState(false)
  const [loged, setLoged] = useState(false)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [products, setProducts] = useState(JSON.parse(localStorage.getItem('carrito')).articulos)
  const [price, setPrice] = useState({
    subtotal: 0.0,
    extras: 0.0,
    total: 0.0,
  })

  function getPrice() {
    let subtotal = 0
    let extras = 0
    let total = 0

    const productos = JSON.parse(localStorage.getItem('carrito')).articulos

    productos.forEach(item => {
      subtotal += item.precio * item.articulos
      extras += item.precio_envio * item.articulos
    })

    total += subtotal + extras
  
    setPrice({
      subtotal,
      extras,
      total,
    })
  }

  const updateComponent = () => {
    setShouldUpdate(prevState => !prevState)
  }

  function pagar() {
    let USR_ID;
    const url = `${API}decodeUserData`;
    // const url = "https://api.dsynapse.com/desarrollo/planetArt/wip/public/Home/decodeUserData"

    let data = new FormData();
    data.append("jwt", getCookie('userData'));

    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onload = (e) => {
      USR_ID = JSON.parse(e.target.responseText).id;

      if (USR_ID) {
        setUserId(USR_ID)
        setLoged(true);
        setShowModalInfo(true)
        xhr.abort();
      } else {
        setLoged(false);
        alert("Es necesario iniciar sesión para poder realizar el pago.");
        navigate("/iniciar-sesion");
      }
    };
    xhr.send(data);
  }

  

  useEffect(() => {
    setProducts(JSON.parse(localStorage.getItem('carrito')).articulos)
    getPrice()
  }, [shouldUpdate])

  return (
    <>
      <Helmet>
        <title>Carrito de compras - Grupo Gepesa</title>
      </Helmet>

      <motion.div 
        initial={{ opacity: 0, y: 300 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        exit={{ opacity: 0, y: 300 }}
        transition={{ type: 'spring', duration: 2, delay: 0 }} 
        className='section'
      >
        {products && products.map(product => (
          <CartProduct 
            key={product.id}
            actualizar={updateComponent}
            id={product.id}
            title={product.nombre}
            description=""
            price={product.precio}
            quantity={parseInt(product.articulos)}
            image={product.image}
          />
        ))}

        <div className='flex flex-col items-end justify-end'>
          <div className='pt-4'>
            <p className='text-lg font-medium text-right mb-2'>
              Subtotal: $ {formatedNumber(price.subtotal)} MXN
            </p>
            <p className='text-sm font-light text-right mb-2'>
              Envío: $ {formatedNumber(price.extras)} MXN
            </p>
            <p className='text-2xl  font-bold text-right mb-2'>
              Total: <span className='text-green-600'>$ {formatedNumber(price.total)} MXN </span>
            </p>
          </div>
          <div className="py-8">
            <button className="btn-primary py-4 px-8" onClick={() => pagar()}>
              Realizar pago
            </button>
          </div>
        </div>
      </motion.div>

      {showModalSendInfo && <SendInfo userId={userId} onClose={() => setShowModalInfo(false)} />}
    </>
  )
}
