import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../js/_cookies";
import { API } from "../js/_variables";
import Helmet from "react-helmet";

const IniciarSesion = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')

  const login = () => {
    if (!email) {
      alert('Es necesario escribir tu correo electrónico para iniciar sesión')
      return 0
    }
    if (!pass) {
      alert('Es necesario escribir tu contraseña para iniciar sesión')
      return 0
    }

    const queryData = new FormData()
    queryData.append('key', 'testKey')
    queryData.append('mail', email)
    queryData.append('contra', pass)

    axios.post(`${API}usr_login`, queryData)
    .then(res => {
      if (res.status === 200 && res.data[0] === "Login exitoso") {
        setCookie('userData', res.data[1], 30)
        window.location.reload()
      }
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    if (getCookie('userData')) navigate('/')
  }, [])

  return (
    <>
      <Helmet>
        <title>Iniciar sesión - Grupo Gepesa</title>
      </Helmet>

      <section className="section">
        <h1 className="text-primary text-center font-bold text-4xl mb-4">
          Iniciar sesión
        </h1>
        <div className="border rounded shadow max-w-lg mx-auto p-4">
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Correo electrónico:
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-input"
              placeholder="correo@dominio.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Contraseña:
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="form-input"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-end">
            <button className="btn-primary" onClick={() => login()}>Iniciar sesión</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default IniciarSesion;
