import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { checkCookie, getCookie, setCookie } from '../js/_cookies'

const tabs = [
  { id: 1, slug: '/', text: 'Inicio' },
  { id: 2, slug: '/nosotros', text: 'Nosotros' },
  { id: 3, slug: '/contacto', text: 'Contacto' },
  { id: 4, slug: '/catalogo', text: 'Catálogo' },
  // { id: 4, slug: '/carrito', text: 'Carrito' },
  // { id: 2, slug: '/contacto', text: 'Contacto' },
  // { id: 2, slug: '/tienda', text: 'Tienda' },
  // { id: 2, slug: '/mi-cuenta', text: 'Mi cuenta' },
  // { id: 2, slug: '/blog', text: 'Blog' },
  // { id: 3, slug: '/secciones', text: 'Secciones', sub: [
  //   { id: 1, slug: '/primaria', text: 'Primaria' },
  //   { id: 2, slug: '/secundaria', text: 'Secundaria' },
  //   { id: 3, slug: '/preparatoria', text: 'Preparatoria' },
  // ] },
]

export default function Navbar({  }) {
  const location = useLocation()

  const [loged, setLoged] = useState(getCookie('userData'))

  const navMenu = useRef(null)
  const navBar = useRef(null)
  const dropMenu = useRef(null)

  const navMenuClick = () => {
    navMenu.current.classList.toggle('h-0')
    navMenu.current.classList.toggle('h-screen')
  }

  const closeNavMenu = () => {
    navMenu.current.classList.remove('h-screen')
    navMenu.current.classList.add('h-0')
  }
  
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      const window_size = window.innerWidth || document.body.clientWidth
      if (window_size > 768)
        navMenu.current.classList.remove('h-screen')
    })
  }


  useEffect(() => {
    setLoged(getCookie('userData'))
  }, [])

  return (
    <nav ref={navBar} className='fixed top-0 w-screen z-40 bg-darkblue shadow' role='navigation'>
      <div className='container mx-auto flex flex-wrap items-center px-4 lg:px-0 lg:flex-nowrap'>
        <Link className='' to='/'>
            <img 
              src='/logo-blanco.png'
              alt='Grupo Gepesa Logo'
              className='w-auto h-20 my-2 rounded'
            />
        </Link>

        <div className='ml-auto lg:hidden'>
          <button
            onClick={() => navMenuClick()}
            className='flex items-center px-3 py-2 border rounded'
            type='button'
          >
            <svg className='h-3 w-3 text-white' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <title>Menu</title>
              <path fill='currentColor' d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
            </svg>
          </button>
        </div>

        <div ref={navMenu} className='w-full h-0 transition-all ease-out duration-500 lg:transition-none lg:w-auto lg:flex-grow lg:flex lg:items-center overflow-hidden lg:overflow-visible bg-neutral-50'>
          <ul onClick={() => closeNavMenu()} className='flex flex-col duration-300 ease-out sm:transition-none mt-5 mx-4 lg:flex-row lg:items-center lg:mx-0 lg:ml-auto lg:mt-0 lg:pt-0 lg:border-0'>
            {
              tabs.map(tab => {
                return(
                  <li 
                    key={tab.id}
                    onClick={ tab.sub ? () => {dropMenu.current.classList.toggle('hidden')} : () => {} } 
                    className={
                      location.pathname === tab.slug 
                      ? `${ tab.sub && 'dropdown group' } p-2 lg:ml-1 hover:cursor-pointer text-primary hover:bg-primary hover:text-darkblue rounded text-base lg:text-lg transition-all duration-150 uppercase` 
                      : `${ tab.sub && 'dropdown group' } p-2 lg:ml-1 hover:cursor-pointer text-white hover:bg-primary hover:text-darkblue hover:rounded text-base lg:text-lg transition-all duration-150 uppercase`}>
                    {
                      tab.sub 
                      ?
                      <a
                        type='button'  
                        className='font-medium uppercase block lg:inline-block lg:normal-case w-full lg:w-auto'>
                        {tab.text}
                      </a>
                      :
                      <Link  
                        to={tab.slug}
                        className='font-medium uppercase block lg:inline-block lg:normal-case w-full lg:w-auto'>
                          
                            {tab.text}
                          
                      </Link>
                    }
                    {
                      tab.sub &&
                      <div ref={dropMenu} className='dropdown-menu absolute hidden w-64 lg:w-32 lg:mt-4 bg-white overflow-hidden rounded shadow'>
                        <ul>
                          {
                            tab.sub.map((sub) => {
                              return(
                                <li key={sub.id}>
                                  <Link 
                                    to={sub.slug}
                                    className='p-2 hover:bg-slate-100 text-black w-full block'>
                                    
                                      {sub.text}    
                                    
                                  </Link>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    }
                  </li>
                )
              }
              )
            }
            {
              localStorage.getItem('carrito') &&
              <li className='p-2 lg:ml-1 hover:cursor-pointer text-white hover:bg-primary hover:text-darkblue hover:rounded text-base lg:text-lg transition-all duration-150 uppercase'>
                <Link to="/carrito" className='uppercase block lg:inline-block lg:normal-case w-full lg:w-auto text-white font-medium hover:text-darkblue'>
                    Carrito
                </Link>
              </li>
            }
            {
              !loged &&
              <>
                <li className='p-2 lg:ml-1 hover:cursor-pointer text-white hover:bg-primary hover:text-darkblue hover:rounded text-base lg:text-lg transition-all duration-150 uppercase'>
                  <Link to="/iniciar-sesion" className='uppercase block lg:inline-block lg:normal-case w-full lg:w-auto text-white font-medium hover:text-darkblue'>
                    
                      Iniciar sesión
                    
                  </Link>
                </li>
                <li className='p-2 lg:ml-1 hover:cursor-pointer text-white hover:bg-primary hover:text-darkblue hover:rounded text-base lg:text-lg transition-all duration-150 uppercase'>
                  <Link to="/registrarse" className='uppercase block lg:inline-block lg:normal-case w-full lg:w-auto text-white font-medium hover:text-darkblue'>
                    
                      Registrarse
                    
                  </Link>
                </li>
              </>
            }
            {
              loged &&
              <>
              {/* <li className='p-2 lg:ml-1 hover:cursor-pointer text-white hover:bg-primary hover:text-darkblue hover:rounded text-base lg:text-lg transition-all duration-150 uppercase'>
                  <Link to="/mi-cuenta" className='uppercase block lg:inline-block lg:normal-case w-full lg:w-auto text-white font-medium hover:text-darkblue'>
                      Mi cuenta
                  </Link>
                </li> */}
              <li className='p-2 lg:ml-1 hover:cursor-pointer text-white hover:bg-primary hover:text-darkblue hover:rounded text-base lg:text-lg transition-all duration-150 uppercase font-medium'>
                  <button className="border-none text-white font-medium hover:text-darkblue"
                    onClick={() => {
                      setCookie('userData', '', 0)
                      window.location.reload()
                    }}
                  >
                    Cerrar sesión
                  </button>
                </li>
              </>
            }
          </ul>
        </div>
      </div>
    </nav>
  )
};
