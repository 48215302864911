import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { API } from "../js/_variables";
import ProviderCard from "./ProviderCard";

const ProviderSlider = () => {
  const [featuredProducts, setFeaturedProducts] = useState([])

  useEffect(() => {
    // Get Featured Products
    const queryData = new FormData()
    queryData.append('key', 'testKey')

    axios.post(
      `${API}getProveedores`, queryData
    )
    .then(res => {
      if (res.status === 200) {
        setFeaturedProducts(res.data.inventory)
      }
    })
    .catch(err => console.log(err))
  }, [])

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className="py-8"
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1280,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 480,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1280,
            min: 480,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={3}
      swipeable
    >
      {
        featuredProducts.length > 0 &&
        featuredProducts.map(product => (
          <ProviderCard 
            nombre={product.nombre}
            subtitulo={product.subtitulo}
            descripcion={product.descripcion}
            id={product.id}
          />
        ))
      }
    </Carousel>
  );
};

export default ProviderSlider;
