import axios from "axios";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../js/_cookies";
import { API } from "../js/_variables";

const Registrarse = () => {
  const navigate = useNavigate()

  const [nombre, setNombre] = useState('')
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [pass2, setPass2] = useState('')

  const register = () => {
    if (!nombre) {
      alert('Es necesario escribir tu nombre para registarte')
      return 0
    }
    if (!email) {
      alert('Es necesario escribir tu correo electrónico para registarte')
      return 0
    }
    if (!pass) {
      alert('Es necesario crear una contraseña para registarte')
      return 0
    }
    if (!pass2) {
      alert('Es necesario confirmar la contraseña para registrarte')
      return 0
    }
    if (pass !== pass2) {
      alert('Las contraseñas no coinciden')
      return 0
    }

    const queryData = new FormData()
    queryData.append('key', 'testKey')
    queryData.append('mail', email)
    queryData.append('contra', pass)
    queryData.append('nombre', nombre)

    axios.post(`${API}usr_register`, queryData)
    .then(res => {
      if (res.status === 200 && res.data[0] === 'Usuario insertado') {
        alert('La cuenta se ha creado correctamente')
        setCookie('userData', res.data[1], 30)
        window.location.reload()
      }
    })
    .catch(() => {})
  }

  useEffect(() => {
    if (getCookie('userData')) navigate('/')
  }, [])

  return (
    <>
      <Helmet>
        <title>Crear una cuenta - Grupo Gepesa</title>
      </Helmet>

      <section className="section">
        <h1 className="text-primary text-center font-bold text-4xl mb-4">
          Registrarse
        </h1>
        <div className="border rounded shadow max-w-lg mx-auto p-4">
          <div className="form-group">
            <label className="form-label" htmlFor="nombre">
              Nombre completo:
            </label>
            <input
              type="text"
              name="nombre"
              id="nombre"
              className="form-input"
              placeholder="Tu nombre completo"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Correo electrónico:
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-input"
              placeholder="correo@dominio.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Contraseña:
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="form-input"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password2">
              Confirmar contraseña:
            </label>
            <input
              type="password"
              name="password2"
              id="password2"
              className="form-input"
              value={pass2}
              onChange={(e) => setPass2(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-end">
            <button className="btn-primary" onClick={() => register()}>Registrarme</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registrarse;
