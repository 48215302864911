import React from 'react'
import ReactDom from 'react-dom'
import { motion } from 'framer-motion'

export default function ModalBase({children, closeModal}) {
  const close = (e) => {
    if (e.target.id === 'modal-bg') closeModal()
  }

  return ReactDom.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'spring', duration: 0.3, delay: 0 }}  
      className='flex items-center justify-center p-4 md:p-8 fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-40'
      id='modal-bg'
      onClick={(e) => close(e)}
    >
      <motion.div 
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ type: 'spring', duration: 0.5, delay: 0 }} 
        className='max-w-full max-h-full relative overflow-y-auto rounded-2xl bg-white p-4 md:p-8 overflow-hidden z-50'
        onClick={() => {}}
      >
        {children}
      </motion.div>
    </motion.div>,
    document.getElementById('modal')
  )
}
