import { faFacebook, faFacebookSquare, faInstagram, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className="bg-darkblue text-white">
      <div className="section">
        <div className="sm:flex sm:flex-row sm:items-center sm:justify-between">
          <div className="">
            <img 
              src='/logo-blanco.png'
              alt='Logo de Grupo Gepesa'
              width={291}
              height={245}
            />
          </div>
          <div className="">
            <p className='font-bold text-2xl mb-4'>
              Contacto
            </p>
            <ul>
              <li className="mb-2 flex items-center">
                <img 
                  src='/icons/clock-sm.png'
                  alt='Horario'
                  width={25}
                  height={25}
                />
                Lunes a Viernes de 8am - 6pm
              </li>
              <li className="mb-2">
                <a href="https://g.page/pisosgepesa?share" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img 
                    src='/icons/location-sm.png'
                    alt='Ubicación'
                    width={25}
                    height={25}
                  />
                  Matriz, Av. Base Aérea #1460 Zapopan, Jalisco.
                </a>
              </li>
              <li className="mb-2">
                <a href="https://goo.gl/maps/43nqYi8xj2Epmn29A" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img 
                    src='/icons/location-sm.png'
                    alt='Ubicación'
                    width={25}
                    height={25}
                  />
                  Sucursal plaza Centinela Blvd. Bosques de San Isisdro norte #1847-20 Zapopan, Jalisco.
                </a>
              </li>
              <li className="mb-2">
                <a href="tel:+523336855648" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img 
                    src='/icons/phone-sm.png'
                    alt=''
                    width={25}
                    height={25}
                  />
                  (+52) 33 3685 - 5648
                </a>
              </li>
              <li className="mb-2">
                <a href="tel:+523313711206" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img 
                    src='/icons/phone-sm.png'
                    alt=''
                    width={25}
                    height={25}
                  />
                  (+52) 33 1371 - 1206
                </a>
              </li>
              <li className="mb-2">
                <a href="tel:+523323014826" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img 
                    src='/icons/phone-sm.png'
                    alt=''
                    width={25}
                    height={25}
                  />
                  (+52) 33 2301 - 4826
                </a>
              </li>
              <li className="mb-2">
                <a href="mailto:ventas@grupogepesa.com" target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img 
                    src='/icons/email-sm.png'
                    alt=''
                    width={25}
                    height={25}
                  />
                  ventas@grupogepesa.com
                </a>
              </li>
              <li className="mb-2 pl-1">
                <a href="https://www.facebook.com/pisosgepesa/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookSquare} /> /pisosgepesa
                </a>
              </li>
              <li className="mb-2 pl-1">
                <a href="https://www.instagram.com/grupogepesa/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagramSquare} /> /grupogepesa
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
 
export default Footer;