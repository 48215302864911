import React from 'react'
import { useEffect, useState } from "react";
import Slider from '../components/Slider';
import axios from 'axios'
import { API, SLIDERS_ROUTE } from "../js/_variables";
import ProductSlider from '../components/ProductSlider';
import ProviderSlider from '../components/ProviderSlider';
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion';  
import { Link } from 'react-router-dom';
import CatsSlider from '../components/CatsSlider';

export default function Home() {
  const [sliderImages, setSliderImages] = useState(['/home-slider/01.png'])
  const [sliderEmpresas, setEmpresasImages] = useState(['/home-slider/01.png'])
  useEffect(() => {
    // Get Slider Images
    const getSliderFormData = new FormData()
    getSliderFormData.append('key', 'testKey')

    axios.post(
      `${API}/getSlid`, getSliderFormData
    ).then(res => {
      if (res.status === 200) {
        const imagesResponse = []
        if (res.data.slider_1) imagesResponse.push(`${SLIDERS_ROUTE}${res.data.slider_1}`)
        if (res.data.slider_2) imagesResponse.push(`${SLIDERS_ROUTE}${res.data.slider_2}`)
        if (res.data.slider_3) imagesResponse.push(`${SLIDERS_ROUTE}${res.data.slider_3}`)
        if (res.data.slider_4) imagesResponse.push(`${SLIDERS_ROUTE}${res.data.slider_4}`)
        if (res.data.slider_5) imagesResponse.push(`${SLIDERS_ROUTE}${res.data.slider_5}`)

        setSliderImages(imagesResponse)
      }
    })
    .catch(() => {})
  }, [])

  return (
    <>
      <Helmet>
        <title>Grupo Gepesa - Materializa tus ideas</title>
      </Helmet>
      
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ type: 'spring', duration: 1, delay: 0 }} 
      >
        <Slider images={sliderImages} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ type: 'spring', duration: 2, delay: 0 }} 
      >
        <section className="section">
          <ProductSlider />
        </section>
      </motion.div>

      {/* Nosotros preview */}
      <section className="section">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
        <motion.div 
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
            className='flex flex-col justify-center'
          >
            <h2 className='text-black font-bold text-4xl mb-6'>
              Todo lo que necesitas para tu hogar en un solo lugar
            </h2>
            <p className='text-dark font-bold text-2xl mb-4'>
              Ponemos a tu alcance los mejores productos con las mejores marcas de la mejor calidad, para ti, tu familia y tu hogar.
            </p>
            <p className='text-dark'>
              Contamos con un gran variedad de productos y artículos: <br />
              <b>Porcelanatos, pisos, azulejos, WC, grifería, tejas, láminas, adoquín, fachaleta, cantera, adhesivos, molduras, ladrillos, piedra.</b>
            </p>
            <p className='text-dark mb-8'>
              Mas de 7,000 productos para remodelar o construir lo que mas deseas. <br />
              Con <b>GRUPO GEPESA</b>, materializa tus ideas.
            </p>

            <div>
              <Link className="btn-primary inline-block py-4 px-8" to='/nosotros'>
                Leer más
              </Link>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
          >
            <div className='rounded-2xl'>
              <img src="/img/nosotros.png" alt="" className='drop-shadow-xl' />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Productos y servicios */}
      <motion.div 
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ type: 'spring', duration: 2, delay: 0 }}
        className='bg-neutral-200 pb-8 relative'
      >
        <section className="section">
          <h2 className='text-black text-center font-bold text-4xl mb-6'>
            PRODUCTOS Y SERVICIOS
          </h2>

          <CatsSlider />
        </section>
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring', duration: 2, delay: 1 }}
          className='md:absolute bottom-0 left-1/2 right-1/2 md:-translate-x-1/2 md:translate-y-1/2 md:w-2/3 p-4 bg-primary rounded-lg border'
        >
          <div className="flex flex-col md:flex-row items-center gap-8">
            <h2 className='text-white font-medium text-3xl'>
              ¿Necesitas ayuda o asesoría técnica, de instalación o aplicación con alguno de nuestros productos?
            </h2>
            <Link to='/contacto' className='btn-white rounded-full'>
              Contácanos
            </Link>
          </div>
        </motion.div>
      </motion.div>

      {/* Enfoque inspirado */}
      <section className="section mt-16 md:mt-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <motion.div
            initial={{ opacity: 0, x: -300 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -300 }}
            transition={{ type: 'spring', duration: 2 }}
          >
            <h2 className='text-black text-4xl mb-6'>
              Un enfoque inspirado para proyectos <b>residenciales</b> y <b>comerciales</b> de calidad.
            </h2>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 300 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: 300 }}
            transition={{ type: 'spring', duration: 2 }}
          >
            <p className='mb-4'>
              <b>Grupo Gepesa</b> nace con el propósito de atender cada detalle, cada rincón, cada parte de tu hogar con los mejores productos en la mejor calidad.
            </p>
            <small className="text-dark">
              Hoy en día trabajamos con la confianza de que nuestro pasado nos ha preparado para brindarte la mejor atención que mereces.
            </small>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 300 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: 300 }}
            transition={{ type: 'spring', duration: 2 }}
            className='md:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-8 mt-4'
          > 
            <div className="bg-neutral-200 rounded-lg p-4 overflow-hidden">
              <p className="text-medium text-lg mb-4">
                Residencial
              </p>
              <p className="text-dark">
                Todo lo necesario para para darle ese toque elegante a cada rincón de tu hogar, detalles únicos e inigualables.
              </p>
            </div>
            <div className="bg-neutral-200 rounded-lg p-4 overflow-hidden">
              <p className="text-medium text-lg mb-4">
                Rústico
              </p>
              <p className="text-dark">
                En Grupo Gepesa tenemos esos detalles únicos e inigualables. Tejas, adoquín, pisos, ladrillo, piedra, cantera y mas.
              </p>
            </div>
            <div className="bg-neutral-200 rounded-lg p-4 overflow-hidden">
              <p className="text-medium text-lg mb-4">
                Comercial
              </p>
              <p className="text-dark">
                ¿Necesitas algo para tu negocio? Seguro que nosotros lo tenemos, la mejor calidad, las mejores marcas al mejor costo.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Banner */}
      <section className="section">
        <Link to='/contacto'>
          <motion.div
              initial={{ opacity: 0, x: -300 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              exit={{ opacity: 0, x: -300 }}
              transition={{ type: 'spring', duration: 2 }}
            >
              <img src="/img/Banner2.jpg" alt=""/>
          </motion.div>
        </Link>
      </section>

      {/* Experiencia */}
      <section className="section mt-16 md:mt-32">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <motion.div
            initial={{ opacity: 0, x: -300 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -300 }}
            transition={{ type: 'spring', duration: 2 }}
          >
            <img src="/img/experiencia.png" alt="" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 300 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: 300 }}
            transition={{ type: 'spring', duration: 2 }}
          >
            <h2 className="font-medium text-4xl mb-8">
              <b>Expertos</b> con experiencia
            </h2>

            <h3 className="font-medium text-2xl mb-2">
              Garantía en cada producto
            </h3>
            <p className="text-dark mb-6">
              Todos nuestros productos cuentan con garantía, trabajamos solo con las mejores marcas.
            </p>

            <h3 className="font-medium text-2xl mb-2">
              Profesionalismo
            </h3>
            <p className="text-dark mb-6">
              Con <b>GRUPO GEPESA</b> podrás encontrar una atención personalizada, asesoría para tu compra, para tus productos, además de un servicio de instalación si lo requieres, <a href="https://www.entasisgrupoconstructor.com/" target="_blank" rel="noopener noreferrer" className="text-primary">Éntasis Grupo Constructor</a> nos respalda.
            </p>

            <h3 className="font-medium text-2xl mb-2">
              El mejor precio del mercado
            </h3>
            <p className="text-dark mb-6">
              Sabemos lo importante que es para nuestras familias y clientes la economía del hogar, es por eso que vamos de la mano con las mejores marcas del mercado para ofrecerte soluciones al mejor precio y con la mas alta calidad.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 300 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: 300 }}
            transition={{ type: 'spring', duration: 2, delay: 0.5 }}
            className='lg:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-8'
          >
            <div className="bg-neutral-200 rounded-lg p-4 overflow-hidden">
              <p className="text-medium text-lg mb-4">
                Más de 20 años de experiencia
              </p>
              <p className="text-dark">
                Mas de 100,000 clientes satisfechos.
              </p>
            </div>
            <div className="bg-neutral-200 rounded-lg p-4 overflow-hidden">
              <p className="text-medium text-lg mb-4">
                Calidad y precio
              </p>
              <p className="text-dark">
                Los mejores productos en el mejor precio.
              </p>
            </div>
            <div className="bg-neutral-200 rounded-lg p-4 overflow-hidden">
              <p className="text-medium text-lg mb-4">
                Los mejores productos del mercado.
              </p>
              <p className="text-dark">
                Las mejores marcas en un solo lugar.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Proveedores */}
      <section className="section mt-16 md:mt-32">
        <div className="flex justify-center">
          <div className='text-center max-w-lg'>
            <span className="uppercase text-darklight font-bold">
              Nuestros proveedores
            </span>
            <h2 className="text-black font-medium text-3xl">
              ¿Construir con materiales de <b>la mejor calidad</b>, de la mano con <b>las mejores marcas y al mejor precio</b>?
            </h2>
          </div>
        </div>
        <ProviderSlider />
        
      </section>

      {/* Nuestros beneficios */}
      <div className='grid grid-cols-1 md:grid-cols-2'>
        <div>
          <img src="/img/beneficios.jpg" alt="" className='w-full h-full object-cover' />
        </div>
        <div className="bg-black p-8 flex items-center justify-center">
          <div className='lg:'>
            <span className='text-white'>NUESTROS BENEFICIOS</span>
            <h2 className="text-white text-4xl mb-4">
              con <b>GRUPO GEPESA</b>
            </h2>
            <p className="text-white mb-8">
              tendrás garantía en cada articulo para tu hogar, calidad de las mejores marcas de México y el mercado, la atención y el profesionalismo que mereces, además de los mejores precios del mercado, recuerda que todos los meses tenemos alguna promoción para ti. ¡Visítanos!
            </p>
            <Link to='/contacto' className='btn-primary'>
              Contáctanos
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
