import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { API } from './../js/_variables'
import ProductCard from './../components/ProductCard'
import Helmet from 'react-helmet';
import { motion } from 'framer-motion';

const Catalogo = () => {
  const navigate = useNavigate()
  let [router, setRouter] = useSearchParams()
  let categoria = router.get('categoria')
  let provider = router.get('p')

  const [categorias, setCategorias] = useState([])
  const [proveedores, setProveedores] = useState([])
  const [products, setProducts] = useState([])

  function getProducts(filter) {
    const queryData = new FormData()
    queryData.append('key', 'testKey')
    queryData.append('min', 0)
    queryData.append('max', 9999)
    queryData.append('filter', filter)


    axios.post(`${API}getArt`, queryData)
    .then(res => {
      if (res.status === 200) {
        setProducts(res.data.articulos)
      }
    })
    .catch(() => {})
  }

  useEffect(() => {
    // Get Products
    getProducts('')

    // Get Categories
    axios.post(`${API}getCategorias`)
    .then(res => {
      if (res.status === 200 && res.data.status === 'ok') {
        setCategorias(res.data.inventory)
      }
    })
    .catch(() => {})

    // Get Categories
    axios.post(`${API}getProveedores`)
    .then(res => {
      if (res.status === 200 && res.data.status === 'ok') {
        setProveedores(res.data.inventory)
      }
    })
    .catch(() => {})

  }, [])

  return (
    <>
      <Helmet>
        <title>Catálogo - Grupo Gepesa</title>
      </Helmet>
      <section className="section">
        <motion.h1 
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ type: 'spring', duration: 1, delay: 0 }} 
          className='text-primary text-center font-bold text-4xl mb-4'
        >
          Conoce nuestros productos
        </motion.h1>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          <motion.div 
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ type: 'spring', duration: 1, delay: 0 }} 
            className="block border rounded p-4"
          >
            {
              categoria &&
              <select onChange={(e) => navigate(`/catalogo?categoria=${categoria}&p=${e.target.value}`)} className='w-full border-neutral-400 p-2 rounded' defaultValue={'DEFAULT'}>
              <option value='DEFAULT' disabled>Seleccionar proveedor</option>
              <option value=''>Cualquier proveedor</option>
              {
                proveedores.length > 0 &&
                proveedores.map(proov => (
                  <option key={proov.id} value={proov.nombre}>
                      {proov.nombre}
                  </option>
                ))
              }  
            </select>
            }
            {
              !categoria &&
              <select onChange={(e) => navigate(`/catalogo?p=${e.target.value}`)} className='w-full border-neutral-400 p-2 rounded' defaultValue={'DEFAULT'}>
              <option value='DEFAULT' disabled>Seleccionar proveedor</option>
              <option value=''>Cualquier proveedor</option>
              {
                proveedores.length > 0 &&
                proveedores.map(proov => (
                  <option key={proov.id} value={proov.nombre}>
                      {proov.nombre}
                  </option>
                ))
              }  
            </select>
            }
            
          </motion.div>
          
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          <motion.div 
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ type: 'spring', duration: 1, delay: 0 }} 
            className="block lg:hidden border rounded p-4"
          >
            <select onChange={(e) => navigate(`/catalogo?categoria=${e.target.value}&p=${provider}`)} className='w-full border-neutral-400 p-2 rounded' defaultValue={'DEFAULT'}>
              <option value='DEFAULT' disabled>Seleccionar categoría</option>
              <option value=''>Sin categoría</option>
              {
                categorias.length > 0 &&
                categorias.map(cat => (
                  <option key={cat.categoria_id} value={cat.categoria_nombre}>
                      {cat.categoria_nombre}
                  </option>
                ))
              }  
            </select>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: -300 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -300 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }} 
            className='hidden lg:block bg-darkblue border border-darklight rounded p-4'
          >
            {
              (provider) &&
              <Link to={`/catalogo?p=${provider}`} className='border-b text-neutral-400 font-bold border-neutral-200 capitalize block mb-4 p-2 rounded hover:bg-primary hover:text-white transition-all duration-100'>
              Sin categoría
              </Link>
            }
            {
              (!provider) &&
              <Link to={`/catalogo`} className='border-b text-neutral-400 font-bold border-neutral-200 capitalize block mb-4 p-2 rounded hover:bg-primary hover:text-white transition-all duration-100'>
              Sin categoría
              </Link>
            }
            {
              categorias.length > 0 &&
              categorias.map(cat => (
                (provider) &&
                <Link 
                  key={cat.categoria_id} 
                  to={`/catalogo?categoria=${cat.categoria_nombre}&p=${provider}`}
                  className='border-b text-neutral-400 font-bold border-neutral-200 capitalize block mb-4 p-2 rounded hover:bg-primary hover:text-white transition-all duration-100'
                >
                  
                    {cat.categoria_nombre}
                  
                </Link>
              ))
            }
            {
              categorias.length > 0 &&
              categorias.map(cat => (
                (!provider) &&
                <Link 
                  key={cat.categoria_id} 
                  to={`/catalogo?categoria=${cat.categoria_nombre}`}
                  className='border-b text-neutral-400 font-bold border-neutral-200 capitalize block mb-4 p-2 rounded hover:bg-primary hover:text-white transition-all duration-100'
                >
                  
                    {cat.categoria_nombre}
                  
                </Link>
              ))
            }
          </motion.div>
          <motion.div 
          initial={{ opacity: 0, y: 300 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          exit={{ opacity: 0, y: 300 }}
          transition={{ type: 'spring', duration: 1, delay: 0 }} 
          className="lg:col-span-3 p-4"
          >
            {
              provider && categoria && <h2 className='text-neutral-400'>Buscando por categoría: {categoria} y marca: {provider}</h2>
            }
            {
              !provider && categoria && <h2 className='text-neutral-400'>Buscando por categoría: {categoria}</h2>
            }
            {
              !categoria && provider && <h2 className='text-neutral-400'>Buscando por marca: {provider}</h2>
            }
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {
                products &&
                products.map(product => {
                  let productCat = JSON.parse(product.cats.replaceAll('`', '"')).categoria
                  let productProv = product.marca
                  if (!categoria && !provider) {
                    return (
                      <ProductCard 
                        key={product.id}
                        headline={product.nombre}
                        price={product.precio}
                        id={product.id}
                        description={product.descripcion}
                        image={`https://static.grupogepesa.com/assets/img/productos/${product.imagen_1}`}
                      />
                    )
                  }
                  if (categoria === productCat && provider === productProv) {
                    return (
                      <ProductCard 
                        key={product.id}
                        headline={product.nombre}
                        price={product.precio}
                        id={product.id}
                        description={product.descripcion}
                        image={`https://static.grupogepesa.com/assets/img/productos/${product.imagen_1}`}
                      />
                    )
                  }
                  if (categoria === productCat && !provider) {
                    return (
                      <ProductCard 
                        key={product.id}
                        headline={product.nombre}
                        price={product.precio}
                        id={product.id}
                        description={product.descripcion}
                        image={`https://static.grupogepesa.com/assets/img/productos/${product.imagen_1}`}
                      />
                    )
                  }
                  if (provider === productProv && !categoria) {
                    return (
                      <ProductCard 
                        key={product.id}
                        headline={product.nombre}
                        price={product.precio}
                        id={product.id}
                        description={product.descripcion}
                        image={`https://static.grupogepesa.com/assets/img/productos/${product.imagen_1}`}
                      />
                    )
                  }
                  return ''
                })
              }
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}
 
export default Catalogo;