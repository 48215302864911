import React from 'react'
import Helmet from 'react-helmet'

export default function MyAccounts() {
  return (
    <>
      <Helmet>
        <title>Mi cuenta - Grupo Gepesa</title>
      </Helmet>
      <div>
        Mi cuenta
      </div>
    </>
  )
}
