import { Link } from 'react-router-dom'
import { STATIC } from "../js/_variables";

const ProviderCard = ({ nombre, subtitulo, descripcion, id }) => {
  return (
    <div className="rounded border shadow-lg overflow-hidden m-2 flex flex-col relative hover:cursor-pointer hover:scale-105 transition-all duration-300">
      <div className="bg-white shadow-lg rounded-lg border p-4">
            <Link to={`/catalogo?p=${nombre}`}>
            <div className="flex justify-center">
              <img src={`${STATIC}assets/img/proveedores/${id}-profile.png`} alt="" className='w-1/2 rounded-full mb-4' />
            </div>
            <p className="text-xl text-center font-bold">
                {nombre}
            </p>
            </Link>
            <small className="text-primary text-center">
                {subtitulo}
            </small>
            <p className="text-dark text-center mt-6">
                {descripcion}
            </p>
            <div className="flex justify-center">
              <img src={`${STATIC}assets/img/proveedores/${id}-logo.png`} alt="" className='w-1/2' />
            </div>
          </div>
    </div>
    
  );
}
 
export default ProviderCard;