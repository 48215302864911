import React from 'react'
import { motion } from 'framer-motion'
import Helmet from 'react-helmet'

export default function AboutUs() {
  return (
    <div>
      <Helmet>
        <title>Nosotros - Grupo Gepesa</title>
      </Helmet>
      <section className="section">
        <motion.h1
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ type: 'spring', duration: 1, delay: 0 }} 
          className='text-primary text-center font-bold text-5xl mb-8'
        >
          Nosotros
        </motion.h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div 
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
            className='flex flex-col justify-center'
          >
            <h2 className='text-black font-bold text-4xl mb-6'>
              Todo lo que necesitas para tu hogar en un solo lugar
            </h2>
            <p className='text-dark font-bold text-2xl mb-4'>
              Ponemos a tu alcance los mejores productos con las mejores marcas de la mejor calidad, para ti, tu familia y tu hogar.
            </p>
            <p className='text-dark'>
              Contamos con un gran variedad de productos y artículos: <br />
              <b>Porcelanatos, pisos, azulejos, WC, grifería, tejas, láminas, adoquín, fachaleta, cantera, adhesivos, molduras, ladrillos, piedra.</b>
            </p>
            <p className='text-dark'>
              Mas de 7,000 productos para remodelar o construir lo que mas deseas. <br />
              Con <b>GRUPO GEPESA</b>, materializa tus ideas.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
          >
            <div className='rounded-2xl'>
              <img src="/img/nosotros.png" alt="" className='drop-shadow-xl' />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
          >
            <div className='max-h-96 relative overflow-hidden rounded-2xl'>
              <img src="/img/nosotros-02.png" className='object-cover w-full h-full drop-shadow-xl' alt="" />
            </div>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
            className='flex flex-col justify-center'
          >
            <h2 className='text-black text-4xl mb-6'>
              <b>Una sola tienda,</b> <br />
              una sola familia
            </h2>
            <p className='text-dark'>
              Somos una empresa dedicada profundamente a la venta de materiales para acabados, donde contamos con infinidad de materiales de la mas alta calidad, <span className='text-primary'>los mejores productos y las mejores marcas.</span> Contamos con la mas alta calidad en materiales para acabados, todo en un solo lugar.
            </p>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
            className='flex flex-col justify-center'
          >
            <span className="text-dark">
              ¿Tienes algún proyecto para tu hogar?
            </span>
            <h2 className='text-black text-4xl mb-6'>
              Con GRUPO GEPESA, <br />
              <b>Materializa tus ideas</b>
            </h2>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: 'spring', duration: 2, delay: 0 }}
          >
            <p className="text-dark mb-4">
              Si deseas construir o remodelar tu hogar, tenemos todo lo que necesitas: interior o exterior, sala o comedor, cocina, baño o habitación. En GRUPO GEPESA encontraras una gran variedad de artículos de la mejor calidad, por parte de las mejores marcas y al mejor precio del mercado. Pregunta a tu asesor de ventas por las promociones del mes.
            </p>
            <p className="text-dark mb-4">
              Ven y visítanos.
            </p>
            <p className="text-dark mb-4">
              Con <b>GRUPO GEPESA</b>, materializa tus ideas.
            </p>
          </motion.div>
        </div>
      </section> 
    </div>
  )
}
