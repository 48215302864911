// Librerías
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
// CSS
import style from "./../Styles/cart.module.css";
import { formatedNumber } from "../js/formatNumbers";

const CartProduct = (props) => {
  const [cantidad, setCantidad] = useState(props.quantity);
  const [precio, setPrecio] = useState(props.price);
  let actualizar = props.actualizar;
  const MAX_CANTIDAD = 99;
  const MIN_CANTIDAD = 1;

  function sumarCantidad(idToSearch) {
    let inputCantidad = document.querySelector(`#cantidad-${idToSearch}`);
    let cantidadAntigua = parseInt(inputCantidad.value);

    if (cantidadAntigua >= 1 && cantidadAntigua < MAX_CANTIDAD) {
      inputCantidad.value = cantidadAntigua + 1;
      setCantidad(inputCantidad.value);
      actualizar("");
      actualizarCantidadEnCarrito(idToSearch, inputCantidad.value);
    }
  }

  function restarCantidad(idToSearch) {
    let inputCantidad = document.querySelector(`#cantidad-${idToSearch}`);
    let cantidadAntigua = parseInt(inputCantidad.value);

    if (cantidadAntigua > MIN_CANTIDAD) {
      inputCantidad.value = cantidadAntigua - 1;
      setCantidad(inputCantidad.value);
      actualizar("");
      actualizarCantidadEnCarrito(idToSearch, inputCantidad.value);
    }
  }

  function actualizarCantidadEnCarrito(idProducto, nuevaCantidad) {
    let carrito = JSON.parse(localStorage.getItem("carrito"));
    let articulos = carrito.articulos;
    let orden = {};

    let nuevoCarrito = articulos.filter((articulo) => {
      if (articulo.id === idProducto) orden = articulo;

      return articulo.id !== idProducto;
    });

    nuevoCarrito.push({
      id: orden.id,
      nombre: orden.nombre,
      image: orden.image,
      precio: orden.precio,
      articulos: nuevaCantidad,
      precio_envio: orden.precio_envio,
    });

    localStorage.setItem(
      "carrito",
      JSON.stringify({ articulos: nuevoCarrito })
    );
  }

  function eliminarProducto(idToSearch) {
    let carrito = JSON.parse(localStorage.getItem("carrito"));
    let articulos = carrito.articulos;

    let nuevoCarrito = articulos.filter((articulo) => {
      return articulo.id !== idToSearch;
    });

    localStorage.setItem(
      "carrito",
      JSON.stringify({ articulos: nuevoCarrito })
    );
    actualizar("");
  }

  function cantidadActualizadaEnInput(idToSearch, value) {
    setCantidad(value)
    actualizar("")
    actualizarCantidadEnCarrito(idToSearch, value)
  }

  return (
    <div className="w-full border-b grid grid-cols-12">
      <div className="col-span-12 md:col-span-8 flex py-3">
        <div>
          <img
            src={`https://static.grupogepesa.com/assets/img/productos/${props.image}`}
            alt="Product Mini"
            className="aspect-square object-cover"
            style={{ maxHeight: "100px" }}
          />
        </div>
        <div className="pl-2">
          <h5>{props.title}</h5>
          <small className="text-neutral-500">
            Precio individual: $ {formatedNumber(precio)} MXN
          </small>
        </div>
      </div>
      <div
        className={` col-span-6 md:col-span-2 py-2 flex items-center justify-start md:justify-end ${style.cartProductInputContainer}`}
      >
        <div
          className={`flex ml-2 justify-between items-center border rounded ${style.btnMiniPlusMinus}`}
        >
          <button
            type="button"
            className="btn"
            id={`restar-${props.id}`}
            onClick={() => {
              restarCantidad(props.id);
            }}
          >
            <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
          </button>
          <input
            type="number"
            name={`cantidad-${props.id}`}
            id={`cantidad-${props.id}`}
            defaultValue={props.quantity}
            onChange={(e) => cantidadActualizadaEnInput(props.id, e.target.value)}
            className={`form-control border-0 text-center p-4 ${style.quantityMiniButtonPlusMinus}`}
          />
          <button
            type="button"
            className="btn"
            id={`sumar-${props.id}`}
            onClick={() => {
              sumarCantidad(props.id);
            }}
          >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </button>
        </div>
      </div>
      <div className=" col-span-6 md:col-span-2 py-2 flex items-center justify-end md:justify-end">
        <p className="text-2xl text-primary font-medium m-0 p-2">$ {formatedNumber(precio * cantidad)} MXN</p>
      </div>
      <div className="col-span-12 border-t py-1">
        <button
          className="btn btn-link text-red-700 btn-sm decoration-transparent"
          onClick={() => {
            eliminarProducto(props.id);
          }}
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default CartProduct;
