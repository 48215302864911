import { Link } from 'react-router-dom'

const ProductCard = ({ description, headline, image, price, id }) => {
  return (
    <div className="rounded border shadow-lg overflow-hidden m-2 flex flex-col relative hover:cursor-pointer hover:scale-105 transition-all duration-300">
      <Link to={`/productos/${id}`}>
          <div className="w-full aspect-square relative">
            <img 
              src={image}
              alt=''
              className='w-full aspect-square object-cover'
            />
          </div>
          <div className="p-4">
            <p className="font-bold text-primary mb-2">{headline}</p>
            <p className="text mb-2 line-clamp-2">
              {description}
            </p>
            <p className="text-right text-green-600 text-sm">
              $ {price} MXN
            </p>
          </div>
      </Link>
    </div>
  );
}
 
export default ProductCard;