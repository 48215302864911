import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import AboutUs from './pages/AboutUs';
import Carrito from './pages/Carrito';
import Catalogo from './pages/Catalogo';
import Contacto from './pages/Contacto';
import Home from './pages/Home';
import Login from './pages/Login';
import MyAccounts from './pages/MyAccounts';
import PagoExitoso from './pages/PagoExitoso';
import ProductPage from './pages/ProductPage';
import Registro from './pages/Registro';

function App() {
  return (
    <div>
      
      <BrowserRouter>
        <Navbar />
        
        <main className='min-h-screen mt-24'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/nosotros' element={<AboutUs />} />
            <Route path='/contacto' element={<Contacto />} />
            <Route path='/catalogo' element={<Catalogo />} />
            <Route path='/carrito' element={<Carrito />} />
            <Route path='/transaction/success' element={<PagoExitoso />} />
            <Route path='/iniciar-sesion' element={<Login />} />
            <Route path='/registrarse' element={<Registro />} />
            <Route path='/mi-cuenta' element={<MyAccounts />} />
            <Route path='/productos/:productId' element={<ProductPage />} />
            <Route path='*' element={<Home />} />
          </Routes>
        </main>
        
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
